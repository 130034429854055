import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { wait } from '@/helper';
import { createAppAsyncThunk } from '@/helper/redux';
import {
  IAffiliatePartnerBrands,
  IPayloadAffiliatePartnerBrands,
  IPayloadListOnlineStixPartners
} from '@/services/types.affiliatePartnerBrands';

export interface AffiliatePartnerBrands {
  loading: boolean;
  partners: IAffiliatePartnerBrands[];
  onlineStixPartnersData: IPayloadListOnlineStixPartners;
  error: string | undefined;
}

export const fetchAffiliatePartnerBrands = createAppAsyncThunk(
  'fetchAffiliatePartnerBrands',
  async (_, { getState, dispatch }) => {
    const configs = getState().config.configs;
    if (!configs?.urls?.length) {
      await wait(1000);
      dispatch(fetchAffiliatePartnerBrands());
      return Promise.reject(new Error('urls not found'));
    }
    const banners = configs.urls?.find(url => url.name === 'banners')?.value;

    const response = await axios.get<IPayloadAffiliatePartnerBrands>(
      `${banners}/affiliate-partner-brands.json`
    );

    const partners = response.data.partners;

    return {
      partners
    };
  }
);

export const fetchOnlineStixPartners = createAppAsyncThunk(
  'fetchOnlineStixPartners',
  async (_, { getState, dispatch }) => {
    const configs = getState().config.configs;
    if (!configs?.urls?.length) {
      await wait(1000);
      dispatch(fetchOnlineStixPartners());
      return Promise.reject(new Error('urls not found'));
    }
    const banners = configs.urls?.find(url => url.name === 'banners')?.value;

    const response = await axios.get<IPayloadListOnlineStixPartners>(
      `${banners}/list-stix-online.json`
    );
    const partners = response.data.partners;

    return {
      partners
    };
  }
);

const initialState = {
  loading: false,
  partners: [],
  onlineStixPartnersData: {
    partners: []
  },
  error: undefined
} as unknown as AffiliatePartnerBrands;

const affiliatePartnerBrandsSlice = createSlice({
  name: 'affiliatePartnerBrands',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAffiliatePartnerBrands.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchAffiliatePartnerBrands.fulfilled, (state, action) => {
      state.loading = false;
      state.partners = action.payload.partners;
      state.error = undefined;
    });
    builder.addCase(fetchAffiliatePartnerBrands.rejected, (state, action) => {
      state.loading = false;
      state.partners = [];
      state.error = action.error.message;
    });

    builder.addCase(fetchOnlineStixPartners.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchOnlineStixPartners.fulfilled, (state, action) => {
      state.loading = false;
      state.onlineStixPartnersData = action.payload;
      state.error = undefined;
    });
    builder.addCase(fetchOnlineStixPartners.rejected, (state, action) => {
      state.loading = false;
      state.onlineStixPartnersData = initialState.onlineStixPartnersData;
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default affiliatePartnerBrandsSlice.reducer;
